import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
  __name: 'hamburger',
  setup(__props) {
    const store = useStore();
    const toggleClick = () => {
      store.commit('app/changeSiderType');
    };
    const icon = computed(() => {
      return store.getters.siderType ? 'hamburger-opened' : 'hamburger-closed';
    });
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      return _openBlock(), _createElementBlock("div", {
        class: "hamburger-container",
        onClick: toggleClick,
        id: "hamburger"
      }, [_createVNode(_component_svg_icon, {
        icon: icon.value
      }, null, 8, ["icon"])]);
    };
  }
};