import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["onClick"];
import Driver from 'driver.js';
import 'driver.js/dist/driver.min.css';
import { onMounted } from 'vue';
import { steps } from './steps';
import { watchLang } from '@/i18n/watchlang';
import i18n from '@/i18n';
export default {
  __name: 'index',
  setup(__props) {
    const t = i18n.global.t;
    let driver;
    onMounted(() => {
      initDriver();
    });
    const initDriver = () => {
      driver = new Driver({
        animate: false,
        // Whether to animate or not
        opacity: 0.75,
        // Background opacity (0 means only popovers and without overlay)
        padding: 10,
        // Distance of element from around the edges
        allowClose: true,
        // Whether the click on overlay should close or not
        overlayClickNext: false,
        // Whether the click on overlay should move next
        doneBtnText: t('driver.doneBtnText'),
        // Text on the final button
        closeBtnText: t('driver.closeBtnText'),
        // Text on the close button for this step
        stageBackground: '#ffffff',
        // Background color for the staged behind highlighted element
        nextBtnText: t('driver.nextBtnText'),
        // Next button text for this step
        prevBtnText: t('driver.prevBtnText') // Previous button text for this step
      });
    };

    watchLang(initDriver);
    const handleGuide = () => {
      driver.defineSteps(steps(t));
      driver.start();
    };
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      return _openBlock(), _createElementBlock("div", {
        id: "guide",
        onClick: _withModifiers(handleGuide, ["prevent", "stop"])
      }, [_createVNode(_component_svg_icon, {
        icon: "guide"
      })], 8, _hoisted_1);
    };
  }
};