import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ab37db32"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "no-redirect"
};
const _hoisted_2 = ["onClick"];
import { watch, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default {
  __name: 'breadcrumb',
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const breadcrumbList = ref([]);
    const initBreadcrumbList = () => {
      breadcrumbList.value = route.matched;
      console.log(route.matched);
    };
    const handleRedirect = path => {
      router.push(path);
    };
    watch(route, () => {
      initBreadcrumbList();
    }, {
      deep: true,
      immediate: true
    });
    return (_ctx, _cache) => {
      const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");
      const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");
      return _openBlock(), _createBlock(_component_el_breadcrumb, {
        separator: "/"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(breadcrumbList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
            key: index
          }, {
            default: _withCtx(() => [index === breadcrumbList.value.length - 1 ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t(`menus.${item.name}`)), 1)) : (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: "redirect",
              onClick: $event => handleRedirect(item.path)
            }, _toDisplayString(_ctx.$t(`menus.${item.name}`)), 9, _hoisted_2))]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      });
    };
  }
};