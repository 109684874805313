import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-15f2e1a6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "el-dropdown-link"
};
import { ref } from 'vue';
import { useStore } from 'vuex';
import Dialog from './update_password.vue';
export default {
  __name: 'avatar',
  setup(__props) {
    const store = useStore();
    const dialogVisible = ref(false);
    const dialogTitle = ref('');
    const dialogTableValue = ref({});
    const squareUrl = ref('https://lin-xin.gitee.io/example/work/assets/img.cf1cb86c.jpg');
    const logout = () => {
      store.dispatch('app/logout');
    };
    const updatePassword = () => {
      dialogTitle.value = '修改密码';
      dialogTableValue.value = {};
      dialogVisible.value = true;
    };
    return (_ctx, _cache) => {
      const _component_el_avatar = _resolveComponent("el-avatar");
      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
      const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
      const _component_el_dropdown = _resolveComponent("el-dropdown");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_dropdown, null, {
        dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
            onClick: updatePassword
          }, {
            default: _withCtx(() => [_createTextVNode("修改密码")]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
            onClick: logout
          }, {
            default: _withCtx(() => [_createTextVNode("退出")]),
            _: 1
          })]),
          _: 1
        })]),
        default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_avatar, {
          size: 40,
          src: squareUrl.value,
          shape: "square"
        }, null, 8, ["src"])])]),
        _: 1
      }), dialogVisible.value ? (_openBlock(), _createBlock(Dialog, {
        key: 0,
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => dialogVisible.value = $event),
        dialogTitle: dialogTitle.value,
        dialogTableValue: dialogTableValue.value
      }, null, 8, ["modelValue", "dialogTitle", "dialogTableValue"])) : _createCommentVNode("", true)], 64);
    };
  }
};