import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import screenfull from 'screenfull';
import { ref, onMounted, onBeforeMount } from 'vue';
export default {
  __name: 'screenFull',
  setup(__props) {
    const icon = ref(screenfull.isFullscreen);
    const handleFullScreen = () => {
      if (screenfull.isEnabled) {
        screenfull.toggle();
      }
    };
    const changeIcon = () => {
      icon.value = screenfull.isFullscreen;
    };
    onMounted(() => {
      screenfull.on('change', changeIcon);
    });
    onBeforeMount(() => {
      screenfull.off('change');
    });
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      return _openBlock(), _createElementBlock("div", {
        onClick: handleFullScreen,
        id: "screenFul"
      }, [_createVNode(_component_svg_icon, {
        icon: icon.value ? 'exit-fullscreen' : 'fullscreen'
      }, null, 8, ["icon"])]);
    };
  }
};