import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
import { menuList } from '@/api/menu';
import { ref } from 'vue';
import variables from '@/styles/variables.scss';
export default {
  __name: 'index',
  setup(__props) {
    const iconList = ref(['HomeFilled', 'user', 'Money', 'Money', 'shop', 'tickets', 'setting', 'Odometer']);
    const icon = ref('menu');
    const defaultActive = ref(sessionStorage.getItem('path') || '/users');
    const menusList = ref([]);
    const initMenusList = async () => {
      const response = await menuList();
      console.log(response);
      menusList.value = response.list;
    };
    initMenusList();
    const savePath = path => {
      sessionStorage.setItem('path', `/${path}`);
    };
    const handleMenuClick = path => {
      // 处理菜单点击事件，可以根据需要添加其他逻辑
      savePath(path);
    };
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      const _component_el_sub_menu = _resolveComponent("el-sub-menu");
      const _component_el_menu = _resolveComponent("el-menu");
      return _openBlock(), _createBlock(_component_el_menu, {
        "active-text-color": "#ffd04b",
        "background-color": _unref(variables).menuBg,
        class: "el-menu-vertical-demo",
        "default-active": defaultActive.value,
        "text-color": "#fff",
        router: "",
        "unique-opened": "",
        collapse: !_ctx.$store.getters.siderType
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menusList.value, (item, index) => {
          return _openBlock(), _createElementBlock(_Fragment, {
            key: item.id
          }, [!item.children || item.children.length === 0 ? (_openBlock(), _createBlock(_component_el_menu_item, {
            key: 0,
            index: '/' + item.path,
            onClick: $event => handleMenuClick(item.path)
          }, {
            title: _withCtx(() => [_createVNode(_component_el_icon, null, {
              default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(iconList.value[index])))]),
              _: 2
            }, 1024), _createElementVNode("span", null, _toDisplayString(item.auth_name), 1)]),
            _: 2
          }, 1032, ["index", "onClick"])) : (_openBlock(), _createBlock(_component_el_sub_menu, {
            index: item.id,
            key: 'submenu_' + item.id,
            onClick: $event => savePath(item.path)
          }, {
            title: _withCtx(() => [_createVNode(_component_el_icon, null, {
              default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(iconList.value[index])))]),
              _: 2
            }, 1024), _createElementVNode("span", null, _toDisplayString(item.auth_name), 1)]),
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, it => {
              return _openBlock(), _createBlock(_component_el_menu_item, {
                index: '/' + it.path,
                key: it.id,
                onClick: $event => savePath(it.path)
              }, {
                title: _withCtx(() => [_createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(icon.value)))]),
                  _: 1
                }), _createElementVNode("span", null, _toDisplayString(_ctx.$t(`menus.${it.path}`)), 1)]),
                _: 2
              }, 1032, ["index", "onClick"]);
            }), 128))]),
            _: 2
          }, 1032, ["index", "onClick"]))], 64);
        }), 128))]),
        _: 1
      }, 8, ["background-color", "default-active", "collapse"]);
    };
  }
};